import axios from 'axios';
// import {refreshTokenFn} from "../utils/refreshToken";
import jwtDecode from "jwt-decode";
import {logout} from "../store/actions";
import {useDispatch} from "react-redux/lib/hooks/useDispatch";


const DEBUG = false;
const EXPIRATION_THRESHOLD = 60;

const cancelRequest = config => {
    DEBUG && console.log("cancel request", config);

    return {
        ...config,
        cancelToken: new axios.CancelToken(cancel => cancel("internal cancel")),
    };
};
class ClientAPI {
    constructor() {
        this.initialized = false;

        this.instance = null;
        this.store = null;
        this.accessToken = window.localStorage.getItem('access_token');

        // console.log(process.env.CFW);

        this.appBaseUrl = window.config && window.config.front_url ? window.config.front_url : window.location.origin;
        if (this.appBaseUrl.indexOf('localhost') > -1) {
            // this.appBaseUrl = 'https://exclusion-test2-cristian.78-47-73-37.nip.io/';
            // this.appBaseUrl = 'http://local.retail-app.ro/';
            this.appBaseUrl = 'https://vision.slotmonitor.ro/';
            // this.appBaseUrl =

        }
        this.locationId = false;
        this.decoded_access_token = false;
        // refresh request already in progress
        this.refreshRequest = false;

        // this is the list of waiting requests that will retry after the JWT refresh complete
        this.subscribers = [];
    }

    setStore(store) {
        this.store = store;

        this.init();
    }


    setLocation(locationId) {
        if (locationId) {
            this.locationId = locationId;
        }
    }

    init() {
        if (this.instance) {
            if(this.accessToken){
                this.instance.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken;
            } else {
                if (typeof this.instance.defaults.headers.common['Authorization'] !== "undefined") {
                    delete this.instance.defaults.headers.common['Authorization'];
                }
            }
        }

        if (!this.store) {
            return;
        }

        if (this.initialized) {
            return;
        }

        this.initialized = true;

        let self = this;
        this.instance = axios.create({
            baseURL: self.appBaseUrl
        });

        this.instance.defaults.headers.common['Content-Type'] = 'application/json';
        this.instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        this.instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

        this.activateRequestInterceptors();

        this.activateResponseInterceptors();
    }

    activateRequestInterceptors() {
        if (!this.instance) {
            return;
        }

        // let self = this;
        this.instance.interceptors.request.use(async config => {
            if (!config.data) {
                config.data = [];
            }

            if (this.locationId) {

                try {
                    config.data.append("location_id",this.locationId);
                } catch (e) {
                    config.data.location_id = this.locationId;
                }
            }

            try {
                DEBUG && console.log("intercept config", config);

                // load and setup access token
                this.loadAccessToken();

                // load and setup refresh token
                this.loadRefreshToken();

                if(config.url !== '/api/auth/refresh' && config.url !== '/api/auth/login')
                {
                    // if there is no access token, we need to refresh
                    if (!this.access_token) {
                        DEBUG && console.log("no access token");

                        if (!this.refresh_token) {
                            // if there is no refresh token, do logout
                            DEBUG && console.log("no refresh token");

                            throw "no refresh token";
                        }

                        // get new access token
                        await this.refreshToken();
                    } else {
                        const now = Math.round(new Date().getTime() / 1000);

                        DEBUG &&
                        console.log(
                            "access token exp",
                            this.decoded_access_token.exp,
                            "now",
                            now,
                            "valid for",
                            this.decoded_access_token.exp - now
                        );

                        // check if access token is expire
                        if (this.decoded_access_token.exp < now + EXPIRATION_THRESHOLD) {
                            DEBUG && console.log("access token expired");

                            // get new access token
                            await this.refreshToken();
                        }
                    }
                }
            } catch (e) {
                DEBUG && console.error("failed to refresh access token", e);
                this.store.dispatch(logout());
                return cancelRequest(config);
            }

            return {
                ...config,
                headers: {
                    ...config.headers,
                    Authorization: "Bearer " + this.access_token,
                },
            };
        }, error => {
            console.error(`[ERROR] Server request error => ${error}`);
            console.error(`[NETWORK] Error, network disconnected!`);

            return Promise.reject(error);
        });
    }

    activateResponseInterceptors() {
        if (!this.instance) {
            return;
        }

        this.instance.interceptors.response.use(response => {
            return response.data;
        }, async (error) => {

            // some kind of error
            DEBUG && console.error("error response", error);

            // determine if we need to refresh the access token
            const res = this.isTokenExpiredError(error);
            if (res) {
                DEBUG && console.log("access is token expired");
                return this.refreshTokenAndReattemptRequest(error);
            } else if (res === null) {
                DEBUG && console.log("request canceled", res);
                //return;
            } else {
                DEBUG && console.log("not an access token error", res);
            }

            // if the error is due to other reasons, we just throw it back to axios
            return Promise.reject(error);
        });
    }

    refreshToken = async () => {
        if (this.refreshRequest) {
            return this.refreshRequest;
        }

        this.clearAccessToken();

        if (!this.refresh_token) {
            throw ("no refresh token")
        }

        DEBUG && console.log("refresh using token", this.refresh_token);
        let axios = this.getInstance();
        this.refreshRequest = axios({
            method: "post",
            url: '/api/auth/refresh',
            data: {
                refresh_token: this.refresh_token,
            },
        })
            .then(response => {
                DEBUG && console.log("reponse refresh request", response);
                // handle new access token
                const newAccessToken = response.result?.access_token;

                DEBUG && console.log('new access token',newAccessToken);

                if (newAccessToken === undefined) {
                   this.store.dispatch(logout());
                }
                this.saveAccessToken(newAccessToken);
                this.onAccessTokenFetched(newAccessToken);

                // handle new refresh token if received and different from the old one
                const newRefreshToken = response.result?.refresh_token;
                if (newRefreshToken && newRefreshToken !== this.refresh_token) {
                    this.saveRefreshToken(newRefreshToken);
                }
            })
            .catch(e => {
                this.clearRefreshToken();
                throw (e);
            })
            .finally(() => {
                this.refreshRequest = null;
            });

        return this.refreshRequest;
    };

    // use the refresh token to get new access token and buffer requests
    refreshTokenAndReattemptRequest = async (error, ocfg) => {
        try {
            let cfg;

            if (error) {
                cfg = error.response.config;
            } else {
                error = new Error("failed to refresh token");
                cfg = ocfg;
            }

            // check if refresh token is present
            if (!this.refresh_token) {
                // we can't refresh, throw the original error
                return Promise.reject(error);
            }

            // add the original request to retry queue
            const retryOriginalRequest = new Promise(resolve => {
                DEBUG && console.log("adding subscriber", cfg);

                this.addSubscriber(access_token => {
                    cfg.headers.Authorization = "Bearer " + access_token;
                    resolve(axios(cfg));
                });
            });

            /*
      if (!this.isAlreadyFetchingAccessToken) {
        // only one refresh request
        this.isAlreadyFetchingAccessToken = true;

        // use refresh token
        const response = await axios({
          method: 'post',
          url: endpoints.user.refresh,
          data: {
            refresh_token: this.refresh_token
          }
        });

        // new access token is required
        if (!response.data) {
          return Promise.reject(error);
        }

        // handle new access token
        const newAccessToken = response.data.data.access_token;
        this.saveAccessToken(newAccessToken);
        this.onAccessTokenFetched(newAccessToken);

        // hanle new refresh token if received and different from the old one
        const newRefreshToken = response.data.data.refresh_token;
        if (newRefreshToken !== this.refresh_token) {
          this.saveRefreshToken(newRefreshToken);
        }

        // done
        this.isAlreadyFetchingAccessToken = false;
      }
      */
            if (!this.refreshRequest) {
                await this.refreshToken();
            }

            // wait for the refresh token request to complete
            return retryOriginalRequest;
        } catch (err) {
            return Promise.reject(err);
        }
    };

    // called when a new access token has been received
    onAccessTokenFetched(access_token) {
        // after a successful refresh, we start retrying the requests one by one and empty the queue
        this.subscribers.forEach(callback => callback(access_token));
        this.subscribers = [];
    }

    // buffer UI requests
    addSubscriber(callback) {
        this.subscribers.push(callback);
    }

    // decide if an error is because an expired refresh token
    isTokenExpiredError(error) {
        DEBUG && console.log("errorResponse", error, typeof error);

        if (error instanceof axios.Cancel) {
            DEBUG && console.log("internal cancel");
            // request was canceled internally
            return null;
        } else {
            DEBUG && console.log("not internal cancel");
        }

        if (error && error.status === 401) {
            return true;
        }

        return false;
    }

    // access token support

    // save and set new access token
    saveAccessToken(access_token) {
        DEBUG && console.log("save access token", access_token);

        this.access_token = access_token;
        localStorage.setItem("access_token", access_token);
        this.decodeAccessToken();
    }


    // load access token from storage
    loadAccessToken() {
        this.access_token = localStorage.getItem("access_token");
        this.decodeAccessToken();

        DEBUG && console.log("loaded access token", this.access_token);
    }

    // decode access token
    decodeAccessToken() {
        if (this.access_token) {
            try {
                this.decoded_access_token = jwtDecode(this.access_token);

                DEBUG && console.log("decoded access token", this.decoded_access_token);
            } catch (e) {
                console.error("failed to decode acces token", e);
                this.access_token = null;
            }
        }
    }

    // clear access token
    clearAccessToken() {
        localStorage.removeItem("access_token");
        this.access_token = null;
        this.decoded_access_token = null;

        DEBUG && console.log("cleared access token");
    }

    // refresh token support

    // save refresh token
    saveRefreshToken(refresh_token) {
        DEBUG && console.log("save refresh token", refresh_token);

        this.refresh_token = refresh_token;
        localStorage.setItem("refresh_token", refresh_token);
    }

    // load refresh token from storage
    loadRefreshToken() {
        this.refresh_token = localStorage.getItem("refresh_token");

        DEBUG && console.log("loaded refresh token", this.refresh_token);
    }

    // clear refresh token
    clearRefreshToken() {
        localStorage.removeItem("refresh_token");
        this.refresh_token = null;

        DEBUG && console.log("cleared refresh token");
    }

    // retrieve refresh token
    getRefreshToken() {
        return this.refresh_token;
    }

    // retrieve access token
    getAccessToken() {
        return this.access_token;
    }

    getInstance() {
        return this.instance;
    }

    getStore() {
        return this.store;
    }
}

export default new ClientAPI();

