import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import {withTranslation} from "react-i18next";
import * as storeActions from "../../../store/actions";
import PageTitle from "../../UI/PageTitle/PageTitle";
import './ExclusionPicker.scss';

import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"
import {Block} from "@material-ui/icons";

class ExclusionPicker extends Component {

    state = {
        active: false
    }

    componentDidMount() {
        let value = {...this.props.player.self_exclusion_options[0]};
        value.id = value.exclusion_option_request_code;
        this.setState({
            ...this.state,
            active: value
        });
        this.props.pickExclusionPeriod(value);
    }

    handleChange(value) {
        this.setState({
            ...this.state,
            active: value
        });
        this.props.pickExclusionPeriod(value);
    }

    getButtons() {
        let comp = [];
        let groups = [];
        let buttons = this.props.player.self_exclusion_options;
        let index = 0;
        buttons.forEach(value => {
            if (index%3 === 0 && index !== 0) {
                groups.push(<div key={'group-'+index}>
                    {comp}
                </div>)
                comp = [];
            }
            index++;
            value = {...value}
            value.id = value.exclusion_option_request_code;
            value.label = value.value === 0 ? this.props.t("Cancel") : `${value.value} ` +  (value.value === 1 ? this.props.t(`${value.unit}`) : this.props.t(`${value.unit}s`)) ;
            comp.push(<button className={this.state.active.id === value.id ? 'active-button' : ''} key={value.id} onClick={this.handleChange.bind(this,value)}>{value.label}</button>)
        })
        if (index%3 !== 3) {
            groups.push(<div key={'group-1'}>
                {comp}
            </div>)
        }
        return <Carousel>{groups}</Carousel>
    }

    render() {

        return (
            <>
                <div className="error-band">{this.props.t("Start self-exclusion")}</div>
                <div className="exclusion-content">
                    <div className="icon">
                        <Block className={'text-error'}/>
                    </div>
                    <PageTitle title={this.props.t('Exclusion from the game')} subtitle={this.props.t('Access to agencies will be restricted')}/>
                </div>
                <div className="picker-content">
                    <div className="picker-title">{this.props.t("Choose exclusion period")}</div>
                    <div className="picker">
                        {this.getButtons()}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        player: state.player,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        login: (username, password) => dispatch(storeActions.login(username,password)),
        pickExclusionPeriod: (value) => dispatch(storeActions.selectSelfExclusion(value))
    };
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ExclusionPicker)));
