import * as actionTypes from './../actions/actionTypes';
import { produce } from "immer";

const initialState = {
    player_id: '',
    scan_id: '',
    self_exclusion_options: [
        {
            value: 1,
            unit: 'Day'
        },
        {
            value: 3,
            unit: 'Day'
        },
        {
            value: 7,
            unit: 'Day'
        },
        {
            value: 1,
            unit: 'Month'
        },
        {
            value: 3,
            unit: 'Month'
        },
        {
            value: 6,
            unit: 'Month'
        }
    ],
    self_exclusion_selected: false,
    info: {
        first_name: "",
        last_name: "",
    },
    self_exclusion: null,
    blacklist: null

};



const playerReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {

            case actionTypes.druid.SCAN_COMPLETE: {
                draft.player_id = action.result?.data?.player_id;
                draft.scan_id = action.result?.data?.scan_id;
                draft.self_exclusion_options = action.result?.data?.self_exclusion_options ?? [];
                draft.info = action.result?.info;
                draft.self_exclusion = {
                    status: action.result?.data?.auto_exclusion_status ?? false,
                    expiry_date: action.result?.data?.auto_exclusion_expiry_date ?? false,
                    id: action.result?.data?.auto_exclusion_id ?? false
                };
                draft.blacklist = {
                    status: action.result?.data?.blacklist_status ?? false
                }
                break;
            }
            case actionTypes.player.SELECT_SELF_EXCLUSION: {
                draft.self_exclusion_selected = action.data;
                break;
            }
            case actionTypes.player.SET_SIGNATURE: {
                draft.info.signature = action.data;
                break;
            }
            case actionTypes.player.SELF_EXCLUDE_COMPLETE: {
                draft.self_exclusion = {
                    status: action.data?.success ?? false,
                    expiry_date: action.data?.self_exclusion_expiry_date ?? false,
                    id: action.data?.self_exclusion_id ?? false,
                    timestamp: new Date().getTime()/1000
                };
                break;
            }
            case actionTypes.player.SEND_BLACKLIST_COMPLETE: {
                draft.blacklist = {
                    status: action.data?.blacklist_id ?? false,
                    timestamp: new Date().getTime()/1000
                }
                break;
            }
            default:
                break;
        }
    });

export default playerReducer;
